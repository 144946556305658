import { Link } from "react-router-dom";
import React, { useEffect } from "react";

const TermandCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="my-4 p-2 max-w-[1000px] mx-auto">
      <section className="my-4">
        <h2 className="text-3xl text-primary font-bold my-2">
          Terms and Conditions
        </h2>
        <p className="text-primary  font-semibold leading-loose">
          This document is an electronic record in terms of Information
          Technology Act, 2000 and rules there under as applicable and the
          amended provisions pertaining to electronic records in various
          statutes as amended by the Information Technology Act, 2000. This
          electronic record is generated by a computer system and does not
          require any physical or digital signatures. This document is published
          in accordance with the provisions of Rule 3 (1) of the Information
          Technology (Intermediaries guidelines) Rules, 2011 that require
          publishing the rules and regulations, privacy policy and user
          agreement for access or usage of{" "}
          <Link className="underline" href="https://fastside.shop">
            {" "}
            https://fastside.shop/
          </Link>{" "}
          website - Managed by Photon Softwares
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-3xl text-primary font-bold my-2">User Agreement</h2>
        <p className="text-primary  font-semibold leading-loose">
          This User Agreement contains rules and regulations, policies, terms &
          conditions and agreement applicable to any person who may access or
          use Fastside.shop Managed by Photon Softwares, including any
          subdomains, webpages or extension of Fastside.shop Managed by Photon
          Softwares.in; you hereby agree and acknowledge to be bound by the
          terms of this User Agreement. The use of Fastside.shop Managed by
          Photon Softwares and the services provided are subject to the rules
          and regulations, policies, notices, terms & conditions set forth in
          this User Agreement. For the purposes of this User Agreement, ‘User’
          and wherever the context may require ‘You’ (or any variation thereof),
          shall mean any natural or legal person who has agreed to become a user
          of Fastside.shop Managed by Photon Softwares by accessing or browsing
          the Website and/or has registered as a member of Fastside.shop Managed
          by Photon Softwares by submitting identification information/
          registration details using the computer system of Fastside.shop
          Managed by Photon Softwares and accepting the electronic record of the
          User Agreement, has obtained a unique username along and a secret
          alpha-numerical key (password) for using the Website. Amendment:
          Fastside.shop Managed by Photon Softwares (Henceforth referred to as
          “Company”) reserves the right to change this Website and/or alter the
          terms and conditions of this User Agreement at any time and retains
          the right to deny access to anyone who the Company believes has
          violated the provisions of this User Agreement. You are advised that
          any amendment to the User Agreement or rules and policies incorporated
          herein by reference will only be notified on the Website on publicly
          accessible links and you agree by accessing, browsing or using this
          website that this constitutes sufficient notice for all purposes
          against you. The revised version/ terms shall be effective from the
          time that the Company posts the same on the Website. In the event,
          that the User Agreement includes a substantial change, the Company
          will provide 30 days’ prior notice of such substantial change by
          posting the same on the Website and also at the email address provided
          by the User to the Company. For the purposes of this User Agreement,
          ‘substantial change’ means a change to the terms of this User
          Agreement that reduces your rights or increases your responsibilities
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-3xl text-primary font-bold my-2">Definitions</h2>
        <p className="text-primary  font-semibold leading-loose">
          Payment on Billing shall mean a Transaction where the payment for the
          items purchased is paid through the (i) Fastside.shop Managed by
          Photon Softwares authorised Payment Gateway Facility or (ii) the buyer
          (if permitted by the relevant seller) has opted to deposit the
          Transaction Price in the bank account of the Company by way of cash,
          cheque, demand draft or any other mode of payment and such payment of
          the Transaction Price is credited to the bank account of the Company
          within [3] days of booking the Transaction. Payment on Delivery shall
          mean a Transaction where the payment for the items is collected from
          the buyer by the Logistics Partner at the time of delivering the
          goods. In this case, the Buyer shall only make payment in cash and no
          other mode of payment will be accepted. However, this facility is
          available only in limited cities in India and the Company in its sole
          discretion and without notice, reserves the right to add or withdraw
          the cities in which such option may be available. This facility shall
          not be available for delivery of items outside India. Delivery /
          Delivered means physical delivery of the items to the buyer (for which
          a Transaction Price has been paid by the buyer by Payment on Billing
          or the buyer has opted for Payment on Delivery, or such other mode of
          payment approved by the Company) by the seller through a reputed
          courier service or by the Logistics Partner at the address
          communicated by the buyer on Fastside.shop Managed by Photon
          Softwares. In Transit/ Dispatched all mean that the items (for which a
          Transaction Price has been paid by the buyer by Payment on Billing or
          the buyer has opted for Payment on Delivery, or such other mode of
          payment approved by the Company) have been dispatched by the seller
          through a reputed courier service or by the Logistics Partner at the
          address communicated by the buyer on Fastside.shop Managed by Photon
          Softwares. Dispatch Details means the true, accurate and valid data,
          information, details or documents as specified by the Company from
          time to time, which the seller/ Logistics Partner is obligated to
          provide to the Company as proof that the item has been Dispatched
          and/or Delivered, as the case maybe. Information means and shall
          include any confidential and/or personally identifiable information or
          other information provided to the Company or other Users of
          Fastside.shop Managed by Photon Softwares at the time of registration,
          buying or listing process or through any email feature and shall
          include without limitation your name, email address, billing/shipping
          address, phone number and banking / financial information. Issuing
          Bank in respect of a buyer, means any bank that has issued a Valid
          Card (credit/ debit/ cash card) to the buyer or the branch of a bank
          which maintains a valid Bank Account in the name of buyer; with which
          the buyer makes payment of the Transaction Price. [Currently,
          Fastside.shop Managed by Photon Softwares authorised Payment Gateway
          Facility supports online bank transfers from the banks that are
          available while selecting prepay as the payment method. The Company
          may add or remove or suspend any one or more banks directly or through
          any payment gateway facilitators, and such change shall come into
          effect upon the same being posted on Fastside.shop Managed by Photon
          Softwares.] Logistics Partner shall mean reputable logistics and
          courier company(s) appointed by the Company that will provide various
          services of collection and delivery of items, collection of the
          Transaction Price from the buyer in case of Payment on Delivery
          Transactions or such other services that the Company may require.
          Transaction means every electronically generated valid purchase order
          placed by the Buyer for purchasing the items listed by the Seller on
          Fastside.shop Managed by Photon Softwares. Transaction Price means the
          price to be paid for the items to be purchased by the buyer for every
          Transaction and which price shall include, if applicable, the shipping
          charges, insurance charges and all other taxes, duties, costs, charges
          and expenses in respect thereof as charged by the seller. Valid Card
          means any valid credit card/ valid debit card/ valid cash card or any
          other card of whatsoever nature issued by Visa or MasterCard and/or by
          any Issuing Bank or any institution designated to issue such cards and
          lawfully owned by the User of the card at the time of the Transaction
          as well as at the time of Refund, if any. Valid Bank Account shall
          mean a valid and operational bank account in the name of the User of
          which the User is the rightful and legal owner having the ability to
          issue cheques/ demand drafts and make deposits, which in respect of
          the buyer shall be referred to as the Buyer Bank Account and in
          respect of the Seller be referred to as the Seller Bank Account. [Both
          the Buyer Bank Account and Seller Bank Account are required to be with
          a branch of a nationalized bank as per applicable Indian laws or other
          banks which are registered with or approved by Reserve Bank of India
          and such branch and/or account of the Buyer and Seller supports
          Reserve Bank of India’s electronic / online banking system including,
          without limitation, National Electronic Funds Transfer (NEFT) and/or
          Real Time Gross Settlement (RTGS)]. Fastside.shop Managed by Photon
          Softwares authorised Payment Gateway facility means the automated
          electronic payment or collection and remittance facility provided by
          the Company to Buyers and Sellers to facilitate purchase and sale of
          items as well as making payments there for on Fastside.shop Managed by
          Photon Softwares directly through banks or financial institution
          infrastructure or indirectly through payment gateway facility
          providers or through any such facility authorized by the Reserve Bank
          of India to provide enabling support facility for collection and
          remittance of payment. [Currently, Fastside.shop Managed by Photon
          Softwares authorised Payment Gateway Facility supports online bank
          transfers from the banks that are available while selecting the Prepay
          mode as the payment method. The Company may add or remove or suspend
          any one or more banks directly or through any payment gateway
          facilitators, and such change shall come into effect upon the same
          being posted on Fastside.shop Managed by Photon Softwares
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-3xl text-primary font-bold my-2">Eligibility</h2>
        <p className="text-primary  font-semibold leading-loose">
          Fastside.shop Managed by Photon Softwares or any service provided by
          Fastside.shop Managed by Photon Softwares may only be used or accessed
          by such persons who can form legally binding contracts under Indian
          Contract Act, 1872. Persons who are ‘incompetent to contract’ within
          the meaning of the Indian Contract Act, 1872 including minors, persons
          of unsound mind, and undischarged insolvents are not eligible to use
          Fastside.shop Managed by Photon Softwares. A minor is not allowed to
          access or register as a User or sell or purchase any items on
          Fastside.shop Managed by Photon Softwares.
          <br />
          <br />
          If you represent and are registering as a business entity, by
          accepting the User Agreement you represent that such entity has
          sufficient authority under applicable law to enter into the User
          Agreement and you are duly authorized by the business entity to accept
          this User Agreement and you have the authority to bind that business
          entity to this User Agreement.
          <br />
          <br />
          <h2 className="text-2xl text-primary font-bold ">
            Registration and Communication
          </h2>
          By registering on Fastside.shop Managed by Photon Softwares, you agree
          to allow us to contact you through any mode of communication
          (Email/Chat/Telephone call) for any confirmation or service related
          information for the order placed by you, on the contact details
          provided by you. Any kind of legal implication raised, due to the
          incorrect nature of the details provided by you, Fastside.shop Managed
          by Photon Softwares will not be held responsible for the same.
          <br />
          <br />
          You agree that your sole purpose of registering or using Fastside.shop
          Managed by Photon Softwares is to buy or sell unique Indian handicraft
          products including green-crafted and ethnic products or such products
          as may be specifically notified by the Company on the Website from
          time to time (that are permitted to be bought and sold under
          applicable law) and you shall not use Fastside.shop Managed by Photon
          Softwares for any other purpose including for selling or buying
          products other than as mentioned above or products that are not
          allowed under applicable law to be sold or bought by you in any
          manner.
          <br />
          <br />
          You agree to provide true, accurate and complete information while
          registering or for any other purpose when prompted to do so on
          Fastside.shop Managed by Photon Softwares. You are prohibited from
          misrepresenting your identity and agree not to represent yourself as
          another User or login/ register using the identity of any other
          person. You are responsible to maintain and promptly update the
          information provided while registering or for any other purpose on
          Fastside.shop Managed by Photon Softwares to ensure that the
          information provided by you is true, accurate, current and complete at
          all times. If you provide any information that is untrue, inaccurate,
          not current or incomplete or the Company has reasonable grounds to
          deduce that such information is untrue, inaccurate, not current or
          incomplete, or not in accordance with this User Agreement, the Company
          reserves the right to indefinitely suspend or terminate or block your
          use or access to Fastside.shop Managed by Photon Softwares in any
          manner whatsoever.
          <br />
          <br />
          <h2 className="text-2xl text-primary font-bold ">
            Seller Registration:
          </h2>{" "}
          To be eligible to sell items on Fastside.shop Managed by Photon
          Softwares, the User is required to separately register with
          Fastside.shop Managed by Photon Softwares authorised Payment Gateway
          facility on its page on Fastside.shop Managed by Photon Softwares. The
          seller shall provide complete details of the Seller Bank Account as a
          part of the Fastside.shop Managed by Photon Softwares authorised
          Payment Gateway Seller Registration or at a later date in lieu of or
          in addition to the original Seller’s Bank Account..
          <br />
          <br />
          <h2 className="text-2xl text-primary font-bold ">
            Buyer Registration:
          </h2>
          Other than being a User, there is no separate registration requirement
          for buyers. Fastside.shop Managed by Photon Softwares authorised
          Payment Gateway facility is available to Buyers who hold a Valid Card
          or Valid Bank Account.
          <br />
          <br />
          <h2 className="text-2xl text-primary font-bold ">
            Electronic Communication:{" "}
          </h2>
          You agree to keep yourself updated with all data, information and
          communication pertaining to you made available on Fastside.shop
          Managed by Photon Softwares. You further agree that your use of
          Fastside.shop Managed by Photon Softwares or provision of any data or
          information including any correspondence (by email or otherwise) to or
          by the Company is through electronic records and you consent to
          receive communication from the Company via electronic records which
          will be deemed adequate service of notice/ electronic record.
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-3xl text-primary font-bold my-2">Terms of Use</h2>
        <p className="text-primary  font-semibold leading-loose">
          You understand and agree that the Company merely provide hosting
          services to its Users who access Fastside.shop Managed by Photon
          Softwares for purchase and sale of unique Indian products (that are
          permitted to be bought and sold under applicable law). All items
          advertised and/or listed on Fastside.shop Managed by Photon Softwares
          and the contents therein are advertised and listed by Users and are
          third party purchasers and/or sellers as the case may be. You also
          give permission to the Website and the Company to store details and
          records of your usage of Fastside.shop Managed by Photon Softwares
          indefinitely. However, this does not constitute any obligation on the
          part of the Company or the Website to do so
          <br />
          <br />
          Currently the membership on Fastside.shop Managed by Photon Softwares
          is free and the Company does not levy any charges/fees for browsing or
          buying on Fastside.shop Managed by Photon Softwares. However, for the
          purposes of listing any item on Fastside.shop Managed by Photon
          Softwares to be sold or availing other services provided by the
          Company through Fastside.shop Managed by Photon Softwares such as
          packing, warehousing, courier etc., the Seller will be charged fees as
          provided in the Facility Charges Policy incorporated herein by
          reference. The Company reserves the right to introduce new services or
          modify the existing services provided on Fastside.shop Managed by
          Photon Softwares. Additionally, the Company at its sole discretion may
          introduce fees for the new services provided or amend/ introduce fees
          for the existing services, as the case may be. Changes to the User
          Agreement or any of the rules and policies of the Company shall be
          posted on Fastside.shop Managed by Photon Softwares and such changes
          shall automatically become effective immediately after they are posted
          on Fastside.shop Managed by Photon Softwares.
          <br />
          <br />
          All fees/ charges shall be quoted in Indian Rupees and shall be
          payable to Fastside.shop Managed by Photon Softwares within such time
          as specified in the invoice. You are responsible for paying all
          charges/ fees associated with the use of Fastside.shop Managed by
          Photon Softwares and shall be liable to pay any and all applicable
          taxes, charges, cesses etc. which may be levied. In case of any non-
          payment, the Company reserves the right to issue a warning or
          temporarily/ indefinitely suspend or terminate your membership with
          Fastside.shop Managed by Photon Softwares and disallow access to
          Fastside.shop Managed by Photon Softwares. The Company also reserves
          the right to take any legal action against you in case of any non-
          payment of charges/fees to the Company.
          <br />
          <br />
          The Company offers listing, packing, courier, warehousing, design and
          other value added services (Fastside.shop Managed by Photon Softwares
          Logistics) to the sellers who opt to avail any of the Fastside.shop
          Managed by Photon Softwares Logistics services agree to comply with
          the terms and conditions set out in Policy for Fastside.shop Managed
          by Photon Softwares Logistics iincorporated herein by reference. The
          sellers will be charged for such services availed from Fastside.shop
          Managed by Photon Softwares Logistics in accordance with the Facility
          Charges Policy.
          <br />
          <br />
          You agree that the Company may appoint any third party service
          provider, including but not restricted to one or more of the Company’s
          affiliate to provide backend operations and support as instructed by
          the Company from time to time including but not limited to collection,
          processing and remittance of the Transaction Price using the existing
          authorized banking infrastructure to provide enabling support facility
          for collection and remittance of payment including but not limited to
          the Logistics Partner.
          <br />
          <br />
          The Company neither originates nor transmits any communication/
          information on behalf of any User nor does it modify the contents of
          any communication transmitted. The Company has no control over third
          parties and contents generated by the Users on Fastside.shop Managed
          by Photon Softwares.
          <br />
          <br />
          Any information provided by you to the Company or submitted to
          Fastside.shop Managed by Photon Softwares or provide or display to
          other Users of Fastside.shop Managed by Photon Softwares in the
          registration, buying or listing process, in the feedback area or
          through any e-mail communication is solely your responsibility and the
          Company merely is a platform where such information is distributed,
          published, displayed or used by Users. The Company or the Website is
          not liable for accuracy, appropriateness or legality of such
          information.
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-3xl text-primary font-bold my-2">
          User Obligations
        </h2>
        <p className="text-primary  font-semibold leading-loose">
          All contractual terms of the manner and terms & conditions of
          delivery, payment, insurance etc. between the buyer and the seller
          shall be independently agreed with the other users of Fastside.shop
          Managed by Photon Softwares that you may transact with.
          <br />
          <br />
          You will treat Fastside.shop Managed by Photon Softwares as a mere
          passive conduit which is used as a platform by Users to create
          listings or provide information for the purpose of selling their items
          so that such information can be discovered and read by other Users of
          Fastside.shop Managed by Photon Softwares who may wish to purchase
          such items from other Users or provide feedback on items they have
          purchased or sellers who have sold any item to them.
          <br />
          <br />
          You agree and undertake not to host, display, upload, modify, publish,
          transmit, update or share any information or list any information or
          item that:
          <br />
          <br />
          belongs to another person and to which you do not have any right to;
          is grossly harmful, harassing, blasphemous, defamatory, obscene,
          pornographic, paedophilic, libellous, invasive of another’s privacy,
          hateful, or racially, ethnically objectionable, disparaging, relating
          or encouraging money laundering or gambling, or otherwise unlawful in
          any manner whatever; or unlawfully threatening or unlawfully harassing
          including but not limited to “indecent representation of women” within
          the meaning of the Indecent Representation of Women (Prohibition) Act,
          1986; harm minors in any way; infringes any patent, trademark,
          copyright or other proprietary rights or third party’s trade secrets
          or rights of publicity or privacy or shall not be fraudulent or
          involve the sale of counterfeit or stolen items; violates any law for
          the time being in force; deceives or misleads the addressee/ users
          about the origin of such messages or communicates any information
          which is grossly offensive or menacing in nature; impersonate another
          person or use an anonymous proxy; contains software viruses or any
          other computer code, files or programs designed to interrupt, destroy
          or limit the functionality of any computer resource; or contains any
          Trojan horses, worms, time bombs, cancelbots, Easter eggs or other
          computer programming routines that may damage, detrimentally interfere
          with, diminish value of, surreptitiously intercept or expropriate any
          system, data or personal information; threatens the unity, integrity,
          defence, security or sovereignty of India, friendly relations with
          foreign states, or public order or causes incitement to the commission
          of any cognizable offence or prevents investigation of any offence or
          is insulting any other nation; shall not be false, inaccurate or
          misleading; shall not, directly or indirectly, offer, attempt to
          offer, trade or attempt to trade in any item, the dealing of which is
          prohibited or restricted in any manner under the provisions of any
          applicable law, rule, regulation or guideline for the time being in
          force; may give rise to liability on part of the Website or the
          Company or cause any hindrance (in whole or in part) with respect to
          the services of ISPs or other suppliers of the Website or the Company;
          and link directly or indirectly to or include descriptions of items
          that are (i) prohibited under the User Agreement or any other
          applicable law for the time being in force including but not limited
          to the Drugs and Cosmetics Act, 1940, the Drugs and Magic Remedies
          (Objectionable Advertisements) Act, 1954, the Indian Penal Code, 1860,
          Information Technology Act 2000 as amended time to time and rules
          there under or (ii) are at the same time listed for sale on a web site
          other than Fastside.shop Managed by Photon Softwares. You shall not
          promote any website or webpage or link on Fastside.shop Managed by
          Photon Softwares.
          <br />
          <br />
          In case of any violation of the above provisions, the Company has the
          right to immediately terminate the access or usage rights of the user
          to Fastside.shop Managed by Photon Softwares without any notice and
          any such violating information that is displayed or submitted on
          Fastside.shop Managed by Photon Softwares can be removed immediately
          and completely.
          <br />
          <br />
          You shall be responsible for keeping backup versions of the
          information and data provided by you. You hereby agree that you will
          not expect Fastside.shop Managed by Photon Softwares to restore or
          keep back up of your information and data and not hold the Website or
          the Company accountable for any loss of data in any circumstances.
          <br />
          <br />
          You shall not, either alone or in conjunction with other users,
          manipulate or attempt to manipulate the prices of any item being sold
          or purchased on Fastside.shop Managed by Photon Softwares. You will
          also refrain from accessing information or databases in an
          unauthorized manner from Fastside.shop Managed by Photon Softwares or
          servers where information or databases are kept.
          <br />
          <br />
          You shall not attempt to or circumvent or manipulate any of the
          obligations conferred on you by this User Agreement. If such attempt
          is discovered, it will constitute sufficient ground for termination of
          access to Fastside.shop Managed by Photon Softwares and also for
          taking appropriate legal action.
          <br />
          <br />
          In case of any transaction or attempted transaction pertaining to any
          item listed on Fastside.shop Managed by Photon Softwares which is
          violating this User Agreement or applicable laws comes to your
          knowledge, you shall forthwith take all steps to inform the Company of
          such violation at support@Fastside.shop Managed by Photon Softwares.in
          <br />
          <br />
          If you choose to provide feedback on Fastside.shop Managed by Photon
          Softwares which is visible to other users, you shall exercise due care
          while making comments and not make any comments that are not factual
          in nature and shall not post defamatory or illegal or offensive/
          obscene contents.
          <br />
          <br />
          You undertake not to disclose or distribute any other User’s
          Information to a third party, or use the Information for any
          unauthorized purpose including for the purposes of marketing unless
          you have obtained the User’s express consent to do so.
          <br />
          <br />
          You shall not place any advertisements on the Website in any manner.
          Further, you shall not use the Website to promote your own or any
          other persons business or interests on the Website except for
          providing description on a listing for a specific item, unless
          permitted by the Company in writing.
          <br />
          <br />
          You shall not attempt to ‘double dip’ during the course of a dispute
          by receiving or attempting to receive funds from the Company and/or
          its service providers and/or the buyer or seller as the case maybe.
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-3xl text-primary font-bold my-2">Disclaimers</h2>
        <p className="text-primary  font-semibold leading-loose">
          Fastside.shop Managed by Photon Softwares is only a platform where
          users may meet and interact with one another for their transactions.
          The Website or the Company is not and cannot be a party to or control
          in any manner any transaction between two users of Fastside.shop
          Managed by Photon Softwares.
          <br />
          <br />
          All commercial / contractual terms are offered by and agreed to
          between buyers and sellers alone as per principal to principal
          bipartite contractual obligations. The commercial / contractual terms
          include without limitation price, shipping costs, payment methods,
          payment terms, date, period and mode of delivery, warranties related
          to items listed for sale. The Company does not have any control and
          neither does it determine or advise or in any way involves itself in
          the offering or acceptance of such commercial / contractual terms
          between buyers and sellers. Further, you understand that a listing may
          end if another User buys the item, or the User who made the listing
          chooses to end the same or if the listing expires after a stipulated
          period of time.
          <br />
          <br />
          The Company is neither involved in the buying and selling of items on
          the Website nor liable or responsible for any non- performance or
          breach of any contract entered into between the Users (i.e. buyer and
          seller) including but not limited to non-delivery or non-receipt,
          non-payment, damage, breach of representations and warranties provided
          by the seller or any fraud as regards the items listed on
          Fastside.shop Managed by Photon Softwares. The Users acknowledge that
          the Company will not be liable for any damages, interests or claims
          etc. resulting from not processing or any delay in processing a
          Transaction/ Transaction Price which is beyond the control of the
          Company. The Company shall not and is not required to mediate or
          resolve any dispute or disagreement between users.
          <br />
          <br />
          TThe Company does not make any representation or warranty as to the
          attributes (such as quality, worth, marketability, merchantability,
          usefulness) of the items proposed to be sold or offered to be sold or
          purchased on Fastside.shop Managed by Photon Softwares. In particular,
          the Company does not implicitly or explicitly support or endorse the
          sale or purchase of any items on the Website. The Company shall not be
          liable for any errors or omissions, whether on behalf of itself or
          third parties.
          <br />
          <br />
          The Company does not make any representation or warranty as to the
          attributes (such as legal title, creditworthiness, identity etc.) of
          any of its users. You are advised use your best judgment and
          independently verify the bona fides of any particular User that you
          choose to deal with on Fastside.shop Managed by Photon Softwares.
          <br />
          <br />
          Fastside.shop Managed by Photon Softwares is only a venue through
          which Users can reach a larger base to buy and sell unique Indian
          products. The Company is only providing a platform in form of the
          Website for communication and a hosting service for information and it
          is agreed that the contract for sale of any of the items shall be a
          strictly bipartite contract between the seller and the buyer. At no
          time shall any right, title or interest over the items vest with the
          Company nor shall the Company have any obligations or liabilities in
          respect of such contract. The Company is not responsible for
          unsatisfactory or delayed performance of sellers or damages or delays
          as a result of items being out of stock, back ordered or otherwise
          unavailable. All items offered by sellers are only for a restricted
          time and only for the available supply as offered by sellers.
          <br />
          <br />
          The Company and its suppliers, affiliates and service providers make
          available Fastside.shop Managed by Photon Softwares and services
          including the Fastside.shop Managed by Photon Softwares Logistics,
          Fastside.shop Managed by Photon Softwares authorised Payment Gateway
          facility for electronic payment on an “as is” basis and without any
          warranty or condition, express, implied or statutory and specifically
          disclaim any implied warranties of title, merchantability, fitness for
          a particular purpose and non-infringement. The Company specifically
          disclaims any such warranty. You expressly agree that the use of
          Fastside.shop Managed by Photon Softwares and Fastside.shop Managed by
          Photon Softwares authorised payment gateway electronic payment
          facility is at your own risk.
          <br />
          <br />
          You release and indemnify the Company and/or any of its officers and
          representatives from any cost, damage, liability or other consequence
          of any of the actions of the users of Fastside.shop Managed by Photon
          Softwares and specifically waive any claims that you may have in this
          behalf under any applicable law. The Company cannot control the
          information provided by other Users, which is made available on the
          Website notwithstanding the Company’s reasonable efforts in that
          behalf. You may find other User’s information to be offensive,
          harmful, inaccurate, or deceptive. Please use caution and practice
          safe trading when using the Website. Please note that there may be
          risks in dealing with foreign nationals, underage persons or people
          acting under false pretence.
          <br />
          <br />
          Limited liability of the Company In no event shall the Company or its
          suppliers, affiliates and service providers be liable for any direct,
          indirect, incidental, special, consequential or exemplary damages,
          including but not limited to, damages for loss of profits, goodwill,
          use, data or other intangible losses arising (in any manner
          whatsoever, including but not limited to negligence) out of or in
          connection with Fastside.shop Managed by Photon Softwares,
          Fastside.shop Managed by Photon Softwares authorised payment gateway
          facility, services provided by the Logistics Partner or any other
          services or this User Agreement.
          <br />
          <br />
          The Company liability in any circumstance is limited to the amount of
          charges/ fees, if any, paid by you to the Company. The Company, its
          associates, affiliates and service providers and technology partners
          make no representations or warranties about the accuracy, reliability,
          completeness, and/or timeliness of any content, information, software,
          text, graphics, links or communications provided on or through the use
          of Fastside.shop Managed by Photon Softwares or that the operation of
          Fastside.shop Managed by Photon Softwares or the Logistics Partner or
          Fastside.shop Managed by Photon Softwares authorised payment gateway
          facility will be error free and/or uninterrupted. The Company provides
          no guarantee to its Users in respect of the products sold on the
          Website. Consequently, the Company assumes no liability whatsoever for
          any monetary or other damage suffered by you on account of:
          <br />
          <br />
          The delay, failure, interruption, or corruption of any data or other
          information transmitted in connection with use of the Website or
          Fastside.shop Managed by Photon Softwares authorised payment gateway
          facility.
          <br />
          <br />
          Any delay, failure, interruption or errors in the operation of
          Fastside.shop Managed by Photon Softwares or the Logistics Partner or
          Fastside.shop Managed by Photon Softwares authorised payment gateway
          facility. Indemnity
          <br />
          <br />
          You shall indemnify and hold harmless the Company and the Company’s
          parent, subsidiaries, affiliates, third-parties and their respective
          officers, directors, agents, and employees, from any claim or demand,
          or actions including reasonable attorneys’ fees, made by any third
          party or penalty imposed due to or arising out of your breach of the
          User Agreement including the rules and policies incorporated herein by
          reference, or (ii) your violation of any law, rules or regulations or
          the rights of a third party.
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-3xl text-primary font-bold my-2">
          Consent and Privacy Policy
        </h2>
        <p className="text-primary  font-semibold leading-loose">
          By using Fastside.shop Managed by Photon Softwares and/or by providing
          your information, you consent to the collection and use of such
          information disclosed by you on Fastside.shop Managed by Photon
          Softwares on Fastside.shop Managed by Photon Softwares authorised
          payment gateway facility, by the Company. The personal information /
          data including but not limited to the Information provided by the You
          to Fastside.shop Managed by Photon Softwares/ Fastside.shop Managed by
          Photon Softwares authorised payment gateway facility during the course
          of a Transaction shall be treated as strictly confidential and
          retained in accordance with the Privacy Policy which is incorporated
          herein by reference and applicable laws and regulations including but
          not limited to Information Technology Act, 2000 and rules there under.
          The Company does not sell or rent or otherwise disclose your personal
          information to third parties for their marketing purposes without your
          explicit consent and the Company only uses your information in the
          manner described in the Privacy Policy. If you do not agree to your
          Information being transferred or used in this way, please do not use
          Fastside.shop Managed by Photon Softwares.
          <br />
          <br />
          TThe Company views the protection of user’s privacy as a very
          important community principle. The Company clearly understands that
          you and the personal information provided by you is one of the most
          important assets to the Company. The Company stores and processes the
          information provided by you in computers located in India that are
          protected by physical as well as reasonable technological security
          measures and procedures in compliance with the provisions of the
          Information Technology Act, 2000 and rules made thereunder.
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-3xl text-primary font-bold my-2">BREACH</h2>
        <p className="text-primary  font-semibold leading-loose">
          Without limiting other remedies that the Company may pursue, the
          Company may at its sole discretion take such action as it deems fit
          including but not limited to cancellation of the Transaction or
          payments made, limit your activity, immediately remove your
          information or listings, or end your listing, warn other Users of your
          actions, forthwith temporarily/indefinitely suspend or terminate or
          block your membership, and/or refuse to provide you with access to
          Fastside.shop Managed by Photon Softwares or initiate any legal action
          it may deem fit, particularly in the event: <br />
          <br />
          You breach any of the provisions of this User Agreement including any
          of the rules and policies, documents, terms and conditions made
          thereunder which are incorporated therein by reference; Any misuse of
          the Fastside.shop Managed by Photon Softwares authorised payment
          gateway facility The Company is unable to verify or authenticate any
          information provided by you; The Company believes that your actions
          may cause legal liability to the Company, other Users or yourself. No
          actions, omissions or decisions taken by the Company shall waive any
          rights or claims that the Company may have against the User. No
          actions, omissions or decisions taken by the Company shall waive any
          rights or claims that the Company may have against the User.
          <br />
          <br />
          Any User that may have been suspended or blocked may not register or
          attempt to register with Fastside.shop Managed by Photon Softwares or
          use Fastside.shop Managed by Photon Softwares in any manner whatsoever
          until such time that such User is reinstated by the Company.
          Notwithstanding the above, if you breach the User Agreement or the
          rules and policies and other documents incorporated therein by
          reference, the Company reserves the right to recover any amounts due
          and owed by you to the Company and to take strict legal action
          including but not limited to referral to the appropriate police or
          other authorities for initiating criminal or other proceedings against
          me.
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-3xl text-primary font-bold my-2">
          Grievance Redressal Mechanism
        </h2>
        <p className="text-primary  font-semibold leading-loose">
          In case of any grievance, objection or complaint on your part with
          respect to the Website, other Users, Fastside.shop Managed by Photon
          Softwares authorised payment gateway facility or the Company,
          including any complaints or enquiry about suspension, termination or
          blocking of your membership or right to use Fastside.shop Managed by
          Photon Softwares, you should promptly raise such grievance or
          complaint with the designated Grievance Officer atsales.Fastside.shop
          Managed by Photon Softwares@gmail.com and provide him with all
          necessary information and/or documents to enable the Company/
          Grievance Officer to resolve the issue.
          <br />
          <br />
          The name and contact details of the Grievance Officer is published on
          Fastside.shop Managed by Photon Softwares as required under the
          provisions of the Information Technology Act, 2000 and the rules made
          thereunder.
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-3xl text-primary font-bold my-2">General</h2>
        <p className="text-primary  font-semibold leading-loose">
          None of the provisions of this User Agreement shall be deemed to
          constitute a partnership or agency between you and the Company and you
          shall have no authority to bind the Company in any manner whatsoever.
          <br />
          <br />
          Except as explicitly stated otherwise, any notices directed to the
          Company shall be given by email tosales.Fastside.shop Managed by
          Photon Softwares@gmail.com and any notices to you by the Company shall
          be provided to the email address provided by you during the
          registration process. Notice shall be deemed given 24 hours after
          email is sent, unless the sending party is notified that the email
          address is invalid. Alternatively, we may give you notice by
          registered mail, postage prepaid and return receipt requested, to the
          address provided to us during the registration process. In such case,
          notice shall be deemed given 3 days after the date of mailing.
          <br />
          <br />
          If any clause of this User Agreement or the application thereof to any
          User or circumstance shall be deemed invalid, void or for any reason
          unenforceable to any extent, the remainder of this User Agreement and
          the application of such unenforceable provision to Users or
          circumstances other than those as to which it is held invalid or
          unenforceable shall not be affected thereby, and each provision of
          this User Agreement shall be valid and enforceable to the fullest
          extent permitted by law.
          <br />
          <br />
          This User Agreement together with the rules and policies incorporated
          herein by reference constitutes the entire understanding and agreement
          between You and the Company with respect to the subject matter herein.
          <br />
          <br />
          The Company at its sole discretion shall be entitled to assign or
          transfer its rights and obligations under this User Agreement
          hereunder to any other person without your prior consent provided that
          the Company assigns this User Agreement on the same terms or such
          terms that are no less favourable to you.
          <br />
          <br />
          All remedies of the Company under this User Agreement whether provided
          herein or conferred by statute, civil law, common law, custom or trade
          usage, are cumulative and not alternative and may be enforced
          successively or concurrently.
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-3xl text-primary font-bold my-2">GOVERNING LAW</h2>
        <p className="text-primary  font-semibold leading-loose">
          This User Agreement and all rules, policies and documents incorporated
          by reference shall be governed and construed in accordance with the
          laws of India and the Courts in Surat shall have exclusive
          jurisdiction. This document is an electronic record in terms of
          Information Technology Act, 2000 and the amended provisions pertaining
          to electronic records in various statutes as amended by the
          Information Technology Act, 2000. This electronic record is generated
          by a computer system and does not require any physical or
          digital signatures.
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-3xl text-primary font-bold my-2">ARBITRATION</h2>
        <p className="text-primary  font-semibold leading-loose">
          If any dispute arises between you and the Company during your use of
          Fastside.shop Managed by Photon Softwares or the Fastside.shop Managed
          by Photon Softwares authorised payment gateway facility or
          Fastside.shop Managed by Photon Softwares Services or any service
          incidental to the Website or thereafter, in connection with the
          validity, interpretation, implementation or alleged breach of any
          provision of the User Agreement and/or Fastside.shop Managed by Photon
          Softwares authorised payment gateway Agreement, or the rules, policies
          and documents incorporated therein by reference, the dispute shall be
          referred to a sole arbitrator who shall be an independent and neutral
          third party identified by the Company whose decision shall be final.
          The place of arbitration shall be Surat. The Arbitration &
          Conciliation Act, 1996, shall govern the arbitration proceedings. The
          arbitration proceedings shall be in the English language.
        </p>
      </section>
      <section className="my-4">
        <h2 className="text-3xl text-primary font-bold my-2"></h2>
        <p className="text-primary  font-semibold leading-loose"></p>
      </section>
    </div>
  );
};

export default TermandCondition;
